.App {
	width: 100%;
	height: 100%;

	display: flex;
}

canvas {
	width: calc(100% - 300px);
}

.sidePanel {
	padding: 10px;

	border-left: 1px solid black;
}